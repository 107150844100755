import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-reservatie-plugin',
  templateUrl: './reservatie-plugin.component.html',
  styleUrls: ['./reservatie-plugin.component.scss']
})

export class ReservatiePluginComponent implements OnInit {
  htmlToAdd: any[''];
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  ngOnInit() {
    this.loadInnerHtml();
    // this.loadInnerHtml2();
  }

  // private loadInnerHtml2() {
  //   let script = this._renderer2.createElement("script");
  //   script.setAttribute("type", "text/javascript");
  //   script.setAttribute("charset", "utf-8");
  //   script.setAttribute('data-name', 'resengo-widget-iframe-script');
  //   script.innerHTML = '(function(){var k=function(a,c,d,b){if(a.getElementById(d)){if(b){var e=100;var f=function(){setTimeout(function(){e--;if(window.RESENGO_WIDGET_SCRIPT_LOADED)b();else if(0<e)f();else throw Error("resengo script failed to load");},100)};f()}}else{var g=a.getElementsByTagName(c)[0];a=a.createElement(c);a.id=d;a.src="https://static.resengo.com/ResengoWidget";b&&(a.onload=b);g.parentNode.insertBefore(a,g)}},h=function(){return k(document,"script","resengo-flow-widget-script",function(){RESENGO_WIDGET({companyId:"1771841",language:"NL",mode:"iframe"})})};window.attachEvent?window.attachEvent("onload",h):window.addEventListener("load",h,!1)})();';
  //   this._renderer2.appendChild(this._document.getElementById("reservatieBlock2"), script);
  // }
  private loadInnerHtml() {
    let script = this._renderer2.createElement("script");
    script.innerHTML = '(function (d, s, id) {const el = d.getElementsByTagName(s)[0]; if (d.getElementById(id) || el.parentNode == null) {return;} var js = d.createElement(s);  js.id = id; js.async = true; js.src = "https://sdk.zenchef.com/v1/sdk.min.js";  el.parentNode.insertBefore(js, el); })(document, "script", "zenchef-sdk");';
    this._renderer2.appendChild(this._document.getElementById("reservatieBlock2"), script);
  }

}
